import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  catchError,
  lastValueFrom,
  map,
  of,
  retry,
  switchMap,
  tap,
  throwError,
  timer,
} from 'rxjs';
import {
  Dealer,
  DealerViewModel,
  DocumentStatus,
  FetchUserResponse,
  InitiateOtpResponse,
  ReservationDetailsResponse,
  User,
  VehicleResponse,
  VerificationPreference,
} from './auth.service.types';
import { environment } from '@env/environment';
import { jwtDecode } from 'jwt-decode';
import {
  TokenGroup,
  TokenPayload,
  USER_TOKEN_STORAGE_KEY,
  VerifyOtpAPIRequestBody,
  VerifyOtpFailureResponse,
  VerifyOtpResponse,
  VerifyOtpSuccessResponse,
} from './auth.service.types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token?: TokenGroup;
  user?: User;
  user$ = new BehaviorSubject<User | undefined>(undefined);
  dealerViewModel?: DealerViewModel;
  dealerViewModel$ = new BehaviorSubject<DealerViewModel | undefined>(
    undefined
  );
  vehicle?: VehicleResponse;
  startDateTime?: string;
  endDateTime?: string;
  phoneNumber?: string;
  reservationId?: string;
  private idToken?: TokenPayload;

  private otpSession?: string;
  get accessToken(): string | undefined {
    return this.token?.accessToken;
  }

  constructor(private http: HttpClient) {}

  async loadTokenFromStorage(): Promise<void> {
    const tokenRaw = localStorage.getItem(USER_TOKEN_STORAGE_KEY);
    if (tokenRaw) {
      const token = JSON.parse(tokenRaw) as TokenGroup;
      this.idToken = jwtDecode(token.idToken);
      if (!this.user || this.user.phoneNumber !== this.idToken.phone_number) {
        return this.deleteToken();
      }
      this.phoneNumber = this.idToken.phone_number;
      await lastValueFrom(this.refreshToken(token));
    }
  }

  updateUser(user: User): void {
    this.user = {
      ...(this.user || {}),
      ...user,
    };
    this.user$.next(this.user);
  }

  deleteToken(): void {
    this.token = undefined;
    localStorage.removeItem(USER_TOKEN_STORAGE_KEY);
  }

  refreshToken(token: TokenGroup): Observable<VerifyOtpSuccessResponse> {
    return this.http
      .post<VerifyOtpSuccessResponse>(
        environment.baseApiUrl + '/customer/auth/public/refresh/tokens',
        {
          phoneNumber: this.phoneNumber,
          refreshToken: token.refreshToken,
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': environment.customerApiKey,
          }),
        }
      )
      .pipe(
        catchError((err) => this.handleRefreshTokenError(err)),
        switchMap((res) => this.handleRefreshTokenSuccess(res.data)),
        map((res) => {
          const user = res.data;
          this.user = {
            ...user,
            emailVerified: this.idToken?.email_verified,
            phoneNumberVerified: this.idToken?.phone_number_verified,
          };
          this.user$.next(this.user);
          return {
            statusCode: 200,
            message: 'Successfully fetched new tokens',
            data: this.token!,
          };
        })
      );
  }

  isLicenseVerified(): boolean {
    if (!this.idToken) return false;
    if (this.idToken['custom:license_verified'] == 'false') return false;
    const now = new Date();
    const verifiedDate = new Date(this.idToken['custom:verified_date']);
    const verifiedUntil = new Date(
      verifiedDate.setDate(
        verifiedDate.getDate() + +environment.licenseVerificationExpiryDays
      )
    );
    return verifiedUntil > now && this.dealerViewModel?.verificationPreference === VerificationPreference.ECONOMIC;
  }

  private handleRefreshTokenError(err: any): Observable<never> {
    this.deleteToken();
    return EMPTY;
  }

  private handleRefreshTokenSuccess(token: TokenGroup): Observable<any> {
    this.token = token;
    this.saveTokenToStorage();
    return this.fetchUser();
  }

  public fetchUser(): Observable<FetchUserResponse> {
    return this.http.get<FetchUserResponse>(
      environment.baseApiUrl +
        `/customer/private/phone-number/${
          this.user?.phoneNumber || this.phoneNumber
        }`,
      this.httpOptions
    );
  }

  sendOtp(phoneNumber: string): Observable<any> {
    return this.http
      .post<InitiateOtpResponse>(
        environment.baseApiUrl + '/customer/auth/public/initiate',
        {
          phoneNumber,
        },
        this.httpOptions
      )
      .pipe(tap((res) => (this.otpSession = res.data.session)));
  }

  verifyOtp(otpCode: string): Observable<any> {
    const verifyOtpAPIRequestBody: VerifyOtpAPIRequestBody = {
      otpCode,
      session: this.otpSession as string,
      phoneNumber: this.user?.phoneNumber as string,
    };

    return this.http
      .post<any>(
        environment.baseApiUrl + '/customer/auth/public/otp/verify',
        verifyOtpAPIRequestBody,
        this.httpOptions
      )
      .pipe(
        map((res) => this.handleOtpVerifyResponse(res)),
        switchMap(() => {
          return this.fetchUser().pipe(
            map((userResponse) => {
              this.setUser(userResponse.data);
              return {
                statusCode: 200,
              };
            })
          );
        }),

        catchError((err) => this.handleOtpVerifyError(err))
      );
  }

  fetchReservationDetails(reservationId: string): Observable<any> {
    const encodedReservationId = encodeURIComponent(reservationId);
    return this.http
      .get<ReservationDetailsResponse>(
        `${environment.baseApiUrl}/vehicle/public/reservation/${encodedReservationId}`,
        {
          headers: new HttpHeaders().set(
            'x-api-key',
            environment.vehicleApiKey
          ),
        }
      )
      .pipe(
        map((res) => {
          this.vehicle = res.vehicle;
          if (!res.customerPhoneNumber)
            return throwError(() => 'No customer phone number found');
          this.user = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: res.customerPhoneNumber as string,
          };
          this.user$.next(this.user);
          this.reservationId = res.reservation.id;
          this.phoneNumber = res.customerPhoneNumber;
          this.startDateTime = res.reservation.startDateTime;
          this.endDateTime = res.reservation.endDateTime;
          return res;
        })
      );
  }

  getDealerById(dealerId: string): Observable<DealerViewModel | undefined> {
    return this.http
      .get<Dealer>(
        environment.dealerBaseApiUrl + '/dealers/public/by-dealer-id',
        {
          params: new HttpParams().set('dealerId', dealerId),
          headers: new HttpHeaders().set('x-api-key', environment.dealerApiKey),
        }
      )
      .pipe(
        map((response) => {
          console.log(response)
          const viewModel = this.dealerToDealerViewModel(response, dealerId);
          this.dealerViewModel = viewModel;
          this.dealerViewModel$.next(this.dealerViewModel);
          return viewModel;
        })
      );
  }

  private dealerToDealerViewModel(
    dealer: Dealer,
    dealerId: string
  ): DealerViewModel {
    return {
      dealerId,
      name: dealer.name,
      address: `${dealer.address}, ${dealer.city}, ${dealer.state}, ${dealer.zip}`,
      phoneNumber: dealer.phoneNumber,
      dealerLogo: dealer.settings.dealerLogoUrl,
      verificationPreference: dealer.verificationPreference
        ? VerificationPreference[
            dealer.verificationPreference as keyof typeof VerificationPreference
          ]
        : VerificationPreference.NEVER,
    };
  }

  private saveTokenToStorage(): void {
    localStorage.setItem(USER_TOKEN_STORAGE_KEY, JSON.stringify(this.token));
  }

  private handleOtpVerifyResponse(res: VerifyOtpResponse): void {
    const response = res as VerifyOtpSuccessResponse;
    this.token = response.data;
    this.idToken = jwtDecode(this.token.idToken);
  }

  private setUser(user: User): void {
    this.user = {
      ...(this.user as User),
      ...user,
    };
    this.user$.next(this.user);
    localStorage.setItem(USER_TOKEN_STORAGE_KEY, JSON.stringify(this.token));
  }

  private handleOtpVerifyError(err: any): Observable<VerifyOtpFailureResponse> {
    const res = err.error as VerifyOtpFailureResponse;
    if (res.statusCode === 400) {
      this.otpSession = res.data.session;
    }
    return of(res);
  }

  private get httpOptions() {
    return {
      headers: new HttpHeaders().set('x-api-key', environment.customerApiKey),
    };
  }

  public fetchUserUntilDLProcessed(
    maxTries: number,
    initialDelay: number
  ): Observable<FetchUserResponse> {
    return new Observable<FetchUserResponse>((observer) => {
      this.fetchUser().subscribe((res) => {
        if (
          res.data.userLicenseDetails?.documentStatus ===
            DocumentStatus.COMPLETED ||
          res.data.userLicenseDetails?.documentStatus === DocumentStatus.FAILED
        ) {
          observer.next(res);
          observer.complete();
        } else {
          observer.error(res);
          observer.complete();
        }
      });
    }).pipe(
      this.backoff(maxTries, initialDelay),
      map((userResponse) => {
        this.setUser(userResponse.data);
        return userResponse;
      })
    );
  }

  private backoff<T>(maxTries: number, initialDelay: number) {
    return retry<T>({
      count: maxTries,
      delay: (error, retryCount) => timer(initialDelay * retryCount ** 2),
    });
  }
}
