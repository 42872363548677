export const USER_TOKEN_STORAGE_KEY = 'userToken';
export const USER_INFO_STORAGE_KEY = 'userInfo';

export interface APIResponse {
  statusCode: number;
  message: string;
}

export interface RefreshTokenResponse extends APIResponse {
  data: {
    accessToken: string;
  };
}

export interface TokenGroup {
  refreshToken: string;
  idToken: string;
  accessToken: string;
}

export interface VerifyOtpSuccessResponse extends APIResponse {
  data: TokenGroup;
}

export interface VerifyOtpFailureResponse extends APIResponse {
  data: {
    session: string;
  };
}
export type InitiateOtpResponse = VerifyOtpFailureResponse;

export type VerifyOtpResponse =
  | VerifyOtpSuccessResponse
  | VerifyOtpFailureResponse;

export interface TokenPayload {
  phone_number: string;
  email_verified: boolean;
  phone_number_verified: boolean;
  'custom:license_verified': string;
  'custom:verified_date': string;
}

export interface VerifyOtpAPIRequestBody {
  session: string;
  otpCode: string;
  phoneNumber: string;
}

export interface UserLicenseDetails {
  licenseNumber?: string;
  licenseExpDate?: string;
  licenseState?: string;
  licenseCity?: string;
  licenseStreet?: string;
  licenseZipcode?: string;
  licenseFrontImagePath?: string;
  licenseBackImagePath?: string;
  dateOfBirth?: string;
  documentStatus?: DocumentStatus;
}

export enum DocumentStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export interface UserInsuranceDetails {
  insurerName?: string;
  agentName?: string;
  policyNumber?: string;
  insuranceExpDate?: string;
  insurerPhoneNumber?: string;
  insuranceImagePath?: string;
}

export interface PersonalInfo {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  emailVerified?: boolean;
  phoneNumberVerified?: boolean;
}

export interface User extends PersonalInfo {
  dateOfBirth?: string;
  userLicenseDetails?: UserLicenseDetails;
  userInsuranceDetails?: UserInsuranceDetails;
}

export interface FetchUserResponse extends APIResponse {
  data: User;
}
export type ReservationState = 'pending' | 'cancelled' | 'paymentSubmitted';

export interface ReservationDetailsResponse {
  reservation: {
    id: string;
    state: ReservationState;
    startDateTime: string;
    endDateTime: string;
  };
  vehicle: VehicleResponse;
  customerPhoneNumber?: string;
}

interface VehiclePrice {
  type: 'daily_price' | 'weekly_price';
  value: string;
  id: string;
}

interface Reservation {
  reservationId: string;
  startDateTime: string;
  endDateTime: string;
  [key: string]: any;
}

interface _Vehicle {
  imageUrl: string[];
  dealerId: string;
  vin: string;
  priority: string;
  make: string;
  state: string;
  year: string;
  color: string;
  trim: string;
  fuelType: string;
  passengerCap: string;
  bootCap: string;
  category: string;
  type: string;
  drive: string;
  fuelAircon: string;
  model: string;
  features?: string[];
  transmissionType: string;
  reservations: Reservation[];
}

/** API Responses */

export interface VehicleResponse extends _Vehicle {
  prices: VehiclePrice[];
  location: {
    lat: number;
    lon: number;
  };
}

export interface BusinessHour {
  day: string;
  opening: string | null;
  closing: string | null;
  status: 'OPENS' | 'CLOSED';
  _id: string;
}

export interface DealerViewModel {
  dealerId: string;
  name: string;
  address: string;
  phoneNumber: string;
  verificationPreference: VerificationPreference;
  dealerLogo?: string;
}

export interface Dealer {
  _id: string;
  name: string;
  address: string;
  zip: string;
  city: string;
  state: string;
  settings: {
    dealerLogoUrl: string;
    businessHours: {
      message: string;
      entries: BusinessHour[];
    };
  };
  phoneNumber: string;
  verificationPreference: string;
}

export enum VerificationPreference {
  ALWAYS = 'ALWAYS',
  ECONOMIC = 'ECONOMIC',
  NEVER = 'NEVER',
}
