import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  forkJoin,
  map,
  mergeMap,
  of,
  tap,
} from 'rxjs';
import {
  ExtensionType,
  FileType,
  IMAGE_FOLDERS,
  PatchUserRequest,
  PreAuthResponse,
} from './app.service.types';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthService } from './auth/auth.service';
import {
  User,
  UserInsuranceDetails,
  UserLicenseDetails,
} from './auth/auth.service.types';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  currentStep$ = new BehaviorSubject<number>(1);
  preAuthUrls?: Partial<Record<FileType, string>>;

  constructor(private http: HttpClient, private authService: AuthService) { }

  uploadFilesToGCS(files: Record<FileType, File>): Observable<any> {
    if (!this.preAuthUrls)
      return of({ statusCode: 400, message: 'No PreAuth Urls found' });
    const preAuthUrls = this.preAuthUrls as Record<FileType, string>;

    const uploadRequests = Object.keys(this.preAuthUrls).reduce(
      (acc: any, folderName) => {
        const preAuthPath: string = preAuthUrls[folderName as FileType];

        const uploadFile: File = files[folderName as FileType];
        acc[folderName] = this.createUploadRequest(preAuthPath, uploadFile);
        return acc;
      },
      {}
    );
    return forkJoin(uploadRequests)
  }

  getAndSetPreAuthUrl(
    folderName: FileType,
    extension: ExtensionType,
    contentType: string
  ): Observable<any> {
    return this.createPreAuthRequest(folderName, extension, contentType).pipe(
      map((response) => {
        const preSignedUrl = response.data.preSignedUrl;
        if (!this.preAuthUrls) {
          this.preAuthUrls = {};
        }
        this.preAuthUrls[folderName] = preSignedUrl;
        return preSignedUrl;
      })
    );
  }

  getDownloadUrls(
  ): Observable<Record<string, string>> {
    const preAuthRequests = ['LicenseFront', 'LicenseBack', 'Insurance'].reduce(
      (acc: any, type) => {
        acc[type] = this.createPreAuthDownloadRequest(type);
        return acc;
      },
      {}
    );
    return forkJoin(preAuthRequests).pipe(
      map((res) => {
        const _res = res as Record<string, PreAuthResponse>;
        let response: Record<string, string> = {};
        Object.keys(_res).forEach((type) => {
          response[type] = _res[type].data.preSignedUrl;
        });
        return response;
      })
    );
  }

  updateUserDetails(user: PatchUserRequest): Observable<any> {
    return this.http
      .patch(
        environment.baseApiUrl + '/customer/private/update',
        user,
        this.httpOptions
      )
      .pipe(
        tap(() => {
          const splitter = 'amazonaws.com/';
          // Get relative urls as backend only store relative urls
          const licenseFront =
            user.userLicenseDetails?.licenseFrontImagePath?.split(splitter)[1] ?? this.authService.user?.userLicenseDetails?.licenseFrontImagePath;
          const licenseBack =
            user.userLicenseDetails?.licenseBackImagePath?.split(splitter)[1] ?? this.authService.user?.userLicenseDetails?.licenseBackImagePath;
          const insurance =
            user.userInsuranceDetails?.insuranceImagePath?.split(splitter)[1] ?? this.authService.user?.userInsuranceDetails?.insuranceImagePath;
          // update user details with the new image paths
          this.authService.updateUser({
            ...(this.authService.user as User),
            userLicenseDetails: {
              ...(this.authService.user as User).userLicenseDetails,
              licenseFrontImagePath: licenseFront,
              licenseBackImagePath: licenseBack,
            },
            userInsuranceDetails: {
              ...(this.authService.user as User).userInsuranceDetails,
              insuranceImagePath: insurance,
            },
          });
        })
      );
  }

  updateInsuranceAndLicenseDetails(
    license: UserLicenseDetails,
    insurance: UserInsuranceDetails,
    dateOfBirth: string
  ): Observable<any> {
    return this.http.patch(
        environment.baseApiUrl + '/customer/private/update',
        {
          dateOfBirth,
          userLicenseDetails: license,
          userInsuranceDetails: insurance,
        },
        this.httpOptions
      )
      .pipe(
        map(() =>
          this.authService.updateUser({
            ...(this.authService.user as User),
            dateOfBirth,
            userLicenseDetails: license,
            userInsuranceDetails: insurance,
          })
        )
      );
  }

  private updateUserDetailsWithUploadFiles(
    preAuthUrls: Record<FileType, string>
  ): Observable<any> {
    return this.updateUserDetails({
      userLicenseDetails: {
        licenseFrontImagePath: preAuthUrls['LicenseFront'].split('?')[0],
        licenseBackImagePath: preAuthUrls['LicenseBack'].split('?')[0],
      },
      userInsuranceDetails: {
        insuranceImagePath: preAuthUrls['Insurance'].split('?')[0],
      },
    });
  }

  private createPreAuthRequest(
    type: FileType,
    extension: ExtensionType,
    contentType: string
  ): Observable<PreAuthResponse> {
    return this.http.post<PreAuthResponse>(
      environment.baseApiUrl + '/express-checkin/private/upload',
      {
        type,
        extension,
        contentType,
      },
      this.httpOptions
    );
  }

  private createPreAuthDownloadRequest(
    type: string
  ): Observable<PreAuthResponse> {
    return this.http.post<PreAuthResponse>(
      environment.baseApiUrl + '/express-checkin/private/download',
      {
        type,
      },
      this.httpOptions
    );
  }

  private createUploadRequest(
    preAuthUploadUrl: string,
    file: File
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': file.type,
      }),
    };
    return this.http.put(preAuthUploadUrl, file, httpOptions);
  }

  initiateScreening(): Observable<any> {
    return this.http
      .post(
        environment.baseApiUrl + '/customer/private/mvr/initiate',
        undefined,
        this.httpOptions
      );
  }

  initiateOcr(): Observable<any> {
    return this.http
      .post(
        environment.baseApiUrl + '/express-checkin/private/ocr/initiate',
        undefined,
        this.httpOptions
      );
  }

  completeCheckIn(reservationId: string): Observable<any> {
    return this.http
      .post(
        `${environment.baseApiUrl}/express-checkin/private/complete/${reservationId}`,
        undefined,
        this.httpOptions
      );
  }

  private get httpOptions() {
    return {
      headers: new HttpHeaders().set('x-api-key', environment.customerApiKey),
    };
  }
}
